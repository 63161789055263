<template>
    <div v-if="question" class="board-container">
        <div role="table" class="board-table board-table--view">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        {{ question.subject }}
                    </div>
                </div>
                <div role="row">
                    <div role="cell">
                        <ul class="board-view-data">
                            <!-- <li>문의유형 : {{ question.type }}</li> -->
                            <li>{{$t("common.date_created")}} : {{ question.createdAt.toDate() }}</li>
                        </ul>
                    </div>
                </div>
                <!-- <div role="row">
                    <div role="cell">
                        <div class="board-view-file">
                            <span class="head">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 409 513.6" style="enable-background:new 0 0 409 513.6;" xml:space="preserve">
                                    <path d="M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"/>
                                </svg>
                                첨부파일 :
                            </span>
                            <ul class="lists">
                                <li><a href="">파일제목_01.jpg,</a></li>
                                <li><a href="">파일제목_02.jpg,</a></li>
                                <li><a href="">파일제목_03.jpg,</a></li>
                                <li><a href="">파일제목_04.jpg,</a></li>
                                <li><a href="">파일제목_05.jpg</a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
                <div v-if="question.content" role="row">
                    <div role="cell">
                        <div class="board-content" v-html="question.content.replace(/\n/g,'<br>')"></div>
                    </div>
                </div>
                <div v-if="question.reply" role="row">
                    <div role="cell">
                        <div class="board-content" v-html="question.reply.replace(/\n/g,'<br>')"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div role="table" class="board-table board-table--preview">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">
                        이전글
                    </div>
                    <div role="cell">
                        <div class="title"><a href="">이전 게시글 제목입니다.</a></div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">
                        이전글
                    </div>
                    <div role="cell">
                        <div class="title"><a href="">다음 게시글 제목입니다.</a></div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="board-buttons">
            <div class="left">
                <a class="button button--border" @click="list"><span>{{$t("common.list")}}</span></a>
            </div>
            <!-- <div class="right">
                <a class="button button--border" href=""><span>수정</span></a>
                <a class="button button--border" href=""><span>답글</span></a>
                <a class="button button--primary" href=""><span>삭제</span></a>
                <a class="button button--border" href=""><span>취소</span></a>
                <a class="button button--primary" href=""><span>확인</span></a>
            </div> -->
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    props: ["_id"],
    data(){
        return {
            question: null
        }
    },
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            var { question } = await api.v1.center.questions.get({ _id: this.$props._id });
            this.question = question;
		},

        list(){
            this.$router.go(-1);
        },
	},
}
</script>

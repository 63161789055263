var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_vm.scope.includes('seller') ? _c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.셀러 회원 마이페이지')
    }
  }) : _vm._e(), _vm.scope.includes('buyer') ? _c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.바이어 회원 마이페이지')
    }
  }) : _vm._e(), _c('div', {
    staticClass: "contents"
  }, [_c('v-container', [_c('div', {
    staticClass: "mypage-container"
  }, [_c('mypage-navigation'), _c('div', {
    staticClass: "mypage-contents"
  }, [_c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('router-view', {
    attrs: {
      "name": "question"
    }
  })], 1)])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--input",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('select', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.question.type,
      expression: "question.type"
    }],
    staticClass: "select",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.question, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v("문의유형")]), _vm._l(_vm.questionTypes, function (type, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": type
      }
    }, [_vm._v(_vm._s(type))]);
  })], 2), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.question.subject,
      expression: "question.subject"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('common.title')
    },
    domProps: {
      "value": _vm.question.subject
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.question, "subject", $event.target.value);
      }
    }
  })])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.question.content,
      expression: "question.content"
    }],
    staticStyle: {
      "width": "100%",
      "height": "300px",
      "border": "1px solid #ddd"
    },
    domProps: {
      "value": _vm.question.content
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.question, "content", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "board-buttons board-buttons--right-center"
  }, [_c('div', {
    staticClass: "left"
  }), _c('div', {
    staticClass: "right"
  }, [_c('a', {
    staticClass: "button button--border",
    on: {
      "click": _vm.cancel
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.취소')))])]), _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": _vm.save
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.confirm')))])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
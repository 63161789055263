<template>
    <div class="board-container">
        <div v-show="false" class="board-search">
            <div class="board-search__box">
                <input v-model="filter.searchValue" class="board-search__input" type="text" name="" value="">
                <button class="board-search__button" type="submit" name="button" @click="search"></button>
            </div>
            <div class="board-search__select">
                <select v-model="filter.type" class="board-search__selectbox" name="">
                    <option value="" disabled selected>문의항목을 선택해주세요.</option>
                    <option v-for="(type, index) in questionTypes" :key="index" :value="type">{{ type }}</option>
                </select>
            </div>
        </div>
        <div role="table" class="board-table board-table--inquiry">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader" class="num">{{$t('common.number')}}</div>
                    <div role="columnheader" class="title">{{$t('common.inquiry')}}</div>
                    <!-- <div role="columnheader" class="cell">문의유형</div> -->
                    <div role="columnheader" class="cell">{{$t('common.answer_status')}}</div>
                    <div role="columnheader" class="cell">{{$t("common.date_created")}}</div>
                </div>
            </div>
            <div v-for="(question, index) in questions" :key="question._id" role="rowgroup">
                <div role="row">
                    <div role="cell" class="num">{{ (page - 1) * limit + index + 1 }}</div>
                    <div role="cell" class="title"><div class="title"><a role="link" @click="details(question)">{{ question.subject }}</a></div></div>
                    <!-- <div role="cell" class="cell type">{{ question.type }}</div> -->
                    <div role="cell" class="cell state">
                        <span v-if="question.reply">{{$t("common.answer_complete")}}</span>
                        <span v-else>{{$t("common.unanswered")}}</span>
                    </div>
                    <div role="cell" class="cell date"><span class="date">{{ question.createdAt.toDate() }}</span></div>
                </div>
            </div>
        </div>

        <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>

        <div class="board-buttons">
            <a class="button button--border" href=""><span>{{$t("common.list")}}</span></a>
            <a class="button button--primary" @click="write"><span>{{$t("common.writing")}}</span></a>
        </div>
    </div>
</template>

<script>
import api from "@/api";

import PaginationComponent from "@/components/client/control/pagination-component.vue";

export default {
	components: {
        PaginationComponent
	},
    data() {
        return {
            filter: {
                searchKey: 'subject',
                searchValue: '',
                type: ''
            },

            page: 1,
            pageCount: 0,
            limit: 20,

            questions: [],
            questionTypes: [
                "회원정보 문의",
                "주문/결제 문의",
                "상품 문의",
                "배송 문의",
                "교환/반품 문의",
                "이벤트/혜택 문의",
                "서비스 개선 의견",
                "기타문의"
            ]
        };
    },
	mounted() {
		this.init();
	},
	methods: {
		init() {
            this.search();
            this.$nextTick(() => {
                // 문의내역 - 답변 토글
                (function(){
                    let question = document.querySelectorAll('.board-table--inquiry [role="button"]'),
                    answer = document.querySelectorAll('.board-table--inquiry [role="rowgroup"].answer');

                    for(let i = 0; i < question.length; i++)
                    question[i].addEventListener('click', toggleInquiry);

                    function toggleInquiry(){
                        let idx = this.getAttribute('data-idx'),
                        toggle = document.querySelector('.board-table--inquiry [role="rowgroup"][data-idx-answer="'+idx+'"]');

                        if(toggle !== null){
                            if(toggle.getAttribute('data-answer') === 'opened'){
                                for(let i = 0; i < answer.length; i++)
                                answer[i].removeAttribute('data-answer');
                            } else {
                                for(let i = 0; i < answer.length; i++)
                                answer[i].removeAttribute('data-answer');
                                toggle.setAttribute('data-answer', 'opened');
                            }
                        }
                    }
                })();
            });
		},

        write(){
            this.$router.push(`${this.$route.path}/create`);
        },

        details(question){
            this.$router.push(`${this.$route.path}/${question._id}`)
        },


        async search(){
            var { summary, questions } = await api.v1.center.questions.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.questions = questions;
        },
	},
}
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" " + _vm._s(_vm.question.subject) + " ")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v(_vm._s(_vm.$t("common.date_created")) + " : " + _vm._s(_vm.question.createdAt.toDate()))])])])]), _vm.question.content ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content",
    domProps: {
      "innerHTML": _vm._s(_vm.question.content.replace(/\n/g, '<br>'))
    }
  })])]) : _vm._e(), _vm.question.reply ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content",
    domProps: {
      "innerHTML": _vm._s(_vm.question.reply.replace(/\n/g, '<br>'))
    }
  })])]) : _vm._e()])]), _c('div', {
    staticClass: "board-buttons"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('a', {
    staticClass: "button button--border",
    on: {
      "click": _vm.list
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.list")))])])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "board-search"
  }, [_c('div', {
    staticClass: "board-search__box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.searchValue,
      expression: "filter.searchValue"
    }],
    staticClass: "board-search__input",
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": _vm.filter.searchValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter, "searchValue", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "board-search__button",
    attrs: {
      "type": "submit",
      "name": "button"
    },
    on: {
      "click": _vm.search
    }
  })]), _c('div', {
    staticClass: "board-search__select"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.type,
      expression: "filter.type"
    }],
    staticClass: "board-search__selectbox",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.filter, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": ""
    }
  }, [_vm._v("문의항목을 선택해주세요.")]), _vm._l(_vm.questionTypes, function (type, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": type
      }
    }, [_vm._v(_vm._s(type))]);
  })], 2)])]), _c('div', {
    staticClass: "board-table board-table--inquiry",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.number')))]), _c('div', {
    staticClass: "title",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.inquiry')))]), _c('div', {
    staticClass: "cell",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.answer_status')))]), _c('div', {
    staticClass: "cell",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.date_created")))])])]), _vm._l(_vm.questions, function (question, index) {
    return _c('div', {
      key: question._id,
      attrs: {
        "role": "rowgroup"
      }
    }, [_c('div', {
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      staticClass: "num",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + index + 1))]), _c('div', {
      staticClass: "title",
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_c('a', {
      attrs: {
        "role": "link"
      },
      on: {
        "click": function ($event) {
          return _vm.details(question);
        }
      }
    }, [_vm._v(_vm._s(question.subject))])])]), _c('div', {
      staticClass: "cell state",
      attrs: {
        "role": "cell"
      }
    }, [question.reply ? _c('span', [_vm._v(_vm._s(_vm.$t("common.answer_complete")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("common.unanswered")))])]), _c('div', {
      staticClass: "cell date",
      attrs: {
        "role": "cell"
      }
    }, [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(question.createdAt.toDate()))])])])]);
  })], 2), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('div', {
    staticClass: "board-buttons"
  }, [_c('a', {
    staticClass: "button button--border",
    attrs: {
      "href": ""
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.list")))])]), _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": _vm.write
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.writing")))])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
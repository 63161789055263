<template>
<client-page class="wrap--sub">
	<!-- 서브비주얼 -->
    <sub-visual v-if="scope.includes('seller')" :visual="$t('common.셀러 회원 마이페이지')"></sub-visual>
    <sub-visual v-if="scope.includes('buyer')" :visual="$t('common.바이어 회원 마이페이지')"></sub-visual>

    <div class="contents">
        <v-container>
			<div class="mypage-container">
				<!-- 네비게이션 메뉴 -->
				<mypage-navigation></mypage-navigation>
				<!-- 페이지 콘텐츠 -->
				<div class="mypage-contents">
					<div class="mypage-contents__body">
            			<router-view name="question"></router-view>
					</div>
				</div>
			</div>

        </v-container>
    </div>
</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";

export default {
	components: {
        ClientPage,
		SubVisual,
		MypageNavigation
	},
	mounted() {
		this.init();
	},
	methods: {
		init: function() {
			if(!this.accessToken){
				this.$router.push('/login');
			}
		}
	},
	computed: {
		accessToken(){
			return this.$store.state.accessToken;
		},
		payload(){
			return this.$store.state.payload || {};
		},
		scope(){
			return this.payload.scope || [];
		}
	}
}
</script>

<template>
    <div class="board-container">
        <div role="table" class="board-table board-table--input">
            <div role="rowgroup">
                <!-- <div role="row">
                    <div role="columnheader">작성자</div>
                    <div role="cell">
                        <div class="form-input">
                            <input class="input" type="text">
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">이메일</div>
                    <div role="cell">
                        <div class="form-input">
                            <input class="input" type="text">
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">작성일</div>
                    <div role="cell">
                        <div class="form-input">
                            <input class="input" type="text">
                        </div>
                    </div>
                </div> -->
                <div role="row">
                    <div role="cell">
                        <div class="form-input">
                            <select v-show="false" v-model="question.type" class="select">
                                <option :value="null" disabled>문의유형</option>
                                <option v-for="(type, index) in questionTypes" :key="index" :value="type">{{ type }}</option>
                            </select>
                            <input v-model="question.subject" class="input" type="text" :placeholder="$t('common.title')">
                        </div>
                    </div>
                </div>
                <!-- <div role="row">
                    <div role="columnheader">라디오/체크박스</div>
                    <div role="cell">
                        <div class="radio-wrap">
                            <label class="radio">
                                <input type="radio" name="radio1" value="" class="input">
                                <i class="icon"></i>
                                <span class="text">라디오1</span>
                            </label>
                            <label class="radio">
                                <input type="radio" name="radio1" value="" class="input">
                                <i class="icon"></i>
                                <span class="text">라디오2</span>
                            </label>
                        </div>
                        <div class="check-wrap">
                            <label class="check">
                                <input type="checkbox" name="check1" value="" class="input">
                                <i class="icon"></i>
                                <span class="text">체크박스1</span>
                            </label>
                            <label class="check">
                                <input type="checkbox" name="check1" value="" class="input">
                                <i class="icon"></i>
                                <span class="text">체크박스2</span>
                            </label>
                        </div>
                    </div>
                </div> -->
                <div role="row">
                    <div role="cell">
                        <textarea v-model="question.content" style="width: 100%; height:300px;border:1px solid #ddd"></textarea>
                    </div>
                </div>
                <!-- <div role="row" v-for="(item, index) in files">
                    <div v-if="index == 0" role="columnheader">썸네일 이미지</div>
                    <div v-else role="columnheader">첨부파일 {{index}}</div>
                    <div role="cell">
                        <div class="file-wrap">
                            <label class="file" :for="'upfile' + index">
                                <input :id="'upfile' + index" class="input" type="file" @change="fileChange(index)">
                                <span class="text"><span>{{item.name}}</span></span>
                                <span class="button button--primary">파일선택</span>
                            </label>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- 버튼 모바일에서 가운데 정렬 -->
        <div class="board-buttons board-buttons--right-center">
            <div class="left">
                <!-- <a class="button button--border" href=""><span>수정</span></a>
                <a class="button button--border" href=""><span>삭제</span></a>
                <a class="button button--border" href=""><span>답글</span></a> -->
            </div>
            <div class="right">
                <a class="button button--border" @click="cancel"><span>{{$t('common.취소')}}</span></a>
                <a class="button button--primary" @click="save"><span>{{$t('common.confirm')}}</span></a>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
	components: {
	},
    data() {
        return {
            files: [{ name: "" },{ name: "" },{ name: "" }],
            fileName: "",
            question: {
                type: null,
                subject: null,
                content: null,
            },
            questionTypes: [
                "회원정보 문의",
                "주문/결제 문의",
                "상품 문의",
                "배송 문의",
                "교환/반품 문의",
                "이벤트/혜택 문의",
                "서비스 개선 의견",
                "기타문의"
            ]
        }
    },
	methods : {
        fileChange(index){
            this.files[index].name = event.target.files[0].name;
        },
        cancel(){
            this.$router.go(-1);
        },
        validate(){
            try{
                var question = this.question;
                // if(!question.type) throw new Error("문의유형을 선택해주세요");
                if(!question.subject) throw new Error("문의제목을 입력해주세요");
                if(!question.content) throw new Error("문의내용을 입력해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async save(){
            if(this.validate()){
                await api.v1.center.questions.post(this.question);
                alert("문의가 등록되었습니다");
                this.$router.go(-1);
            }
        },
	},
}
</script>
